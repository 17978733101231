import type { ReactiveVar } from "@apollo/client";
import { makeVar } from "@apollo/client";
import format from "date-fns/format";

import { isProduction } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const subscribeToReactiveVar = <A>(reactiveVar: ReactiveVar<A>, namespace: string) => {
  const onChange = (state: A) => {
    if (!window) return;

    const event = new CustomEvent("rv-live-view", {
      detail: {
        date: new Date().toDateString(),
        time: format(new Date(), "h:mm:ss aaa"),
        namespace,
        state,
      },
    });

    window.dispatchEvent(event);
  };

  const subscribe = (state: A) => {
    onChange(state);
    reactiveVar.onNextChange(subscribe);
  };

  reactiveVar.onNextChange(subscribe);
};

/**
 * makeFSVar is a wrapper for makeVar function.
 * makeFSVar will emit an event anytime
 * the state changes in non-production mode.
 *
 * @param state - the value to be stored
 *
 * @param namespace - a unique identifier for the state.
 * Preferably the file name of the reactive var hook, if there are
 * multiple makeVar in a single file, consider adding a -suffix,
 * e.g., useCatalogSearchVar-brands
 */

const makeFSVar = <B>(state: B, namespace: string): ReactiveVar<B> => {
  if (isProduction()) return makeVar(state);

  const reactiveVar: ReactiveVar<B> = makeVar(state);
  subscribeToReactiveVar(reactiveVar, namespace);

  return reactiveVar;
};

export { makeFSVar };
